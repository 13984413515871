import * as clients from './configs';
import { Features } from './type';
import defaultFeatureState from './default';
import { isCapacitor } from '@zignaly-open/ui';
import { MIN_INVESTMENT_FIREBLOCKS } from 'util/constants';

const { VITE_DEV_ONLY_WHITELABEL: whitelabelNameOverride } = import.meta.env;

export const whitelabel =
  window.__zignalyWhitelabelConfig ||
  (whitelabelNameOverride && clients[whitelabelNameOverride]) ||
  clients.zignaly;

export const isZignaly = /^z0\d+$/.test(whitelabel.slug);
export const isFireblocks = whitelabel.slug !== 'uni_01';

export const isMaintenanceModeFromServer = whitelabel.isMaintenance;

export const isFeatureOn = (feature: Features): boolean => {
  return (
    {
      ...defaultFeatureState,
      ...(whitelabel?.featureOverrides || {}),
      [Features.NewPortfolioLayout]: isFireblocks,
    }[feature] || false
  );
};

export const isAccountRequired =
  isFeatureOn(Features.LoginOnlyAccess) || isCapacitor;

export function getMinInvestmentAmount(coin: string): number {
  const fireblocksMinInvestment = isFireblocks ? MIN_INVESTMENT_FIREBLOCKS : 0;
  if (
    isFeatureOn(Features.MinInvestment) &&
    +whitelabel.minInvestment?.[coin] > fireblocksMinInvestment
  ) {
    return +whitelabel.minInvestment?.[coin];
  }
  return fireblocksMinInvestment;
}

export const isCancelPendingInvestmentAllowed = !isFireblocks;

export const minZScore = whitelabel.marketplaceMinScore ?? 20;

import { styled } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { ZigTypography } from '@zignaly-open/ui';
import { ZigGlobeLanguagesIcon } from '@zignaly-open/ui/icons';

export const NavLink = styled('a')<{ active?: boolean; disabled?: boolean }>`
  color: ${({ theme }) => theme.palette.neutral400};
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.55px;
  text-decoration: none;
  transition: 0.15s linear;

  svg {
    transition: 0.15s linear;
    fill: ${({ theme }) => theme.palette.neutral400};
  }

  ${({ active, theme }) => `
    ${
      active
        ? `
      color: ${theme.palette.highlighted};
      `
        : `
      &:not([disabled]) {
        cursor: pointer;

        transition: 0.15s linear;
        &:hover {
          color: ${theme.palette.highlighted};
          
          svg {
            fill: ${theme.palette.highlighted};
          }
        }
      }
    `
    }
  `}

  &:hover {
    span svg {
      fill: ${({ theme }) => theme.palette.highlighted};
      color: ${({ theme }) => theme.palette.highlighted};
    }
  }
`;

export const NavList = styled('div')`
  display: flex;
  flex-direction: column;

  padding: 12px 0;
  gap: 8px;

  &:first-of-type {
    padding-top: 0;
  }

  &.last {
    border-top: 1px solid #2c2d59;
    margin: 0 -18px;
    padding: 0;
  }
`;

export const Networks = styled('div')`
  display: grid;
  grid-template-columns: repeat(5, minmax(0%, 100%));
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: ${({ theme }) =>
    // ok we dropped the color here EFFORTLESSLY preserving the original color
    lighten(theme.palette.neutral800, (0x1c - 0x12) / 0xff)};
  padding: 19px 32px;
  flex: 1;

  > a {
    line-height: 0;
  }

  svg {
    transition: all 0.15s linear;
  }
`;

export const GlobeLanguagesStyled = styled(ZigGlobeLanguagesIcon)`
  transition: 0.15s linear;
`;

export const LabelButton = styled(ZigTypography)`
  transition: 0.15s linear;
`;

import baseApiPs2 from '../baseApiPs2';
import { injectEndpoints } from 'apis/util';

export const api = injectEndpoints(baseApiPs2, (builder) => ({
  isServerUp: builder.query<string, void>({
    query: () => ({
      url: 'test',
      method: 'GET',
      responseHandler: 'text',
    }),
    extraOptions: {
      // do not report errors
      // we use the text handler so it's not parsed properly
      silent: true,
    },
  }),
}));

export const { useIsServerUpQuery, useLazyIsServerUpQuery } = api;

import React, { Suspense } from 'react';
import Router from './Router';
import {
  isCapacitor,
  ThemeProvider,
  // has to be imported from the same module from where we call the show toast
  ToastContainer,
} from '@zignaly-open/ui';
import { ThemeChartGradients } from '@zignaly-open/ui/charts';
import { ThemeProvider as ThemeProviderMui } from '@mui/material';
import ModalProvider from 'mui-modal-provider';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './apis/store';
import { Provider } from 'react-redux';
import GlobalStyle from './styles';
import { PersistGate } from 'redux-persist/integration/react';
import Header from './components/Navigation/Header';
import UpdateChecker from './components/Navigation/Checkers/UpdateChecker';
import UserKycChecker from './components/Navigation/Checkers/UserKycChecker';
import DateLocaleFixer from './components/Navigation/DateLocaleFixer';
import Tracker from './components/Navigation/Tracker/Tracker';
import useReferralCookie from 'util/hooks/useReferralCookie';
import BottomNavigation from 'components/Navigation/BottomNavigation';
import { zigSuspenseFallback } from './util/suspense';
import ZModal from './components/ZModal';
import ZigErrorBoundary from './util/ZigErrorBoundary';
import './util/i18n/i18nextWhitelabel';
import MissingExchangeChecker from 'components/Navigation/Checkers/MissingExchangeChecker';
import { useWlInstanceThemeWithDebugStyling } from './theme';
import { useMaybeUpdateServiceWorkerOnClose } from './sw/util';
import CapacitorUpdateChecker from 'components/Navigation/Checkers/CapacitorUpdateChecker';
import Maintenance from 'views/Error/Maintenance';
import Cloudflare from 'views/Error/Cloudflare';
import {
  useIsCloudflareBlock,
  useIsMaintenance,
  useMaybeDelayedCheckServerStatus,
} from './apis/globalError/use';
import('./sentry');

export const WrappedInProviders: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const themeMui = useWlInstanceThemeWithDebugStyling();

  return (
    <Provider store={store}>
      <ThemeProvider theme={themeMui}>
        <ThemeProviderMui theme={themeMui}>
          <GlobalStyle />
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            closeButton={false}
            pauseOnHover
            theme='dark'
            style={{ paddingTop: 'env(safe-area-inset-top)' }}
          />
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <ZigErrorBoundary>
                <Suspense fallback={zigSuspenseFallback}>
                  <ModalProvider
                    fallback={<ZModal allowUnauth wide open isLoading />}
                  >
                    {children}
                  </ModalProvider>
                </Suspense>
              </ZigErrorBoundary>
            </BrowserRouter>
          </PersistGate>
        </ThemeProviderMui>
      </ThemeProvider>
    </Provider>
  );
};

function App() {
  useReferralCookie();
  useMaybeUpdateServiceWorkerOnClose();

  const isMaintenance = useIsMaintenance();
  const isCloudflareBlock = useIsCloudflareBlock();
  const globalError = isMaintenance || isCloudflareBlock;

  useMaybeDelayedCheckServerStatus();

  return (
    <>
      {!globalError && <Header />}
      <Suspense fallback={zigSuspenseFallback}>
        <ZigErrorBoundary>
          {globalError ? (
            <>
              {isMaintenance && <Maintenance />}
              {isCloudflareBlock && <Cloudflare />}
            </>
          ) : (
            <>
              <Tracker />
              {isCapacitor ? <CapacitorUpdateChecker /> : <UpdateChecker />}
              <UserKycChecker />
              <MissingExchangeChecker />
              <DateLocaleFixer />
              <ThemeChartGradients />
              <Router />
            </>
          )}
        </ZigErrorBoundary>
        <BottomNavigation />
      </Suspense>
    </>
  );
}

export default () => (
  <WrappedInProviders>
    <App />
  </WrappedInProviders>
);

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { GlobalErrorState } from './types';
import { isMaintenanceModeFromServer } from '../../whitelabel';

const initialState: GlobalErrorState = {
  isMaintenance: isMaintenanceModeFromServer,
  isCloudflareBlock: false,
};

export const globalErrorSlice = createSlice({
  name: 'globalError',
  initialState,
  reducers: {
    setIsMaintenance: (state, action: PayloadAction<boolean>) => {
      state.isCloudflareBlock = false;
      state.isMaintenance = action.payload;
    },
    setIsCloudflareBlock: (state, action: PayloadAction<boolean>) => {
      state.isMaintenance = false;
      state.isCloudflareBlock = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsMaintenance, setIsCloudflareBlock } =
  globalErrorSlice.actions;

export default globalErrorSlice.reducer;

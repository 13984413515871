import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'util/title';
import { ZignalyLogotype, ZigStub } from '@ui/index';
import { Box, Container } from '@mui/material';
import { Block } from '@mui/icons-material';
import { isZignaly, whitelabel } from 'whitelabel';

const Cloudflare: React.FC = () => {
  const { t } = useTranslation('error');
  useTitle(t('cloudflare.title'));
  const logo = !isZignaly ? (
    <img src={whitelabel.logo} id='maintenance__logo' height='32' />
  ) : (
    <ZignalyLogotype width={'134px'} height={'32px'} id={'maintenance__logo'} />
  );

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }, []);

  return (
    <Container>
      <Box mt='14px'>
        <a href='/'>{logo}</a>

        <ZigStub
          title={
            <Box display='flex' alignItems='center' gap={1}>
              <Block sx={{ fontSize: 28 }} />
              {t(`cloudflare.title`)}
            </Box>
          }
          description={t('cloudflare.description')}
        />
      </Box>
    </Container>
  );
};

export default Cloudflare;

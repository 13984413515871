import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import './util/i18n/i18next';
import * as serviceWorkerRegistration from './sw/serviceWorkerRegistration';
import { isFeatureOn, isMaintenanceModeFromServer } from './whitelabel';
import { Features } from '@zignaly-open/ps2-definitions';
import { disablePwaZoom } from './sw/util';
const PwaInstall = React.lazy(() => import('util/pwaInstall'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const shouldUseServiceWorker =
  isFeatureOn(Features.ServiceWorker) && !isMaintenanceModeFromServer;

root.render(
  <React.StrictMode>
    <React.Suspense>
      <App />
    </React.Suspense>
    <React.Suspense>{shouldUseServiceWorker && <PwaInstall />}</React.Suspense>
  </React.StrictMode>,
);

shouldUseServiceWorker
  ? serviceWorkerRegistration.register()
  : serviceWorkerRegistration.unregister();

disablePwaZoom();

import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useIsServerUpQuery, useLazyIsServerUpQuery } from './api';
import { useEffect } from 'react';
import { useIsAuthenticated } from '../user/use';

export function useIsMaintenance() {
  return (
    useSelector((state: RootState) => state.globalError)?.isMaintenance ?? false
  );
}

export function useIsCloudflareBlock() {
  return (
    useSelector((state: RootState) => state.globalError)?.isCloudflareBlock ??
    false
  );
}

export function useCheckMaintenance() {
  const { data } = useIsServerUpQuery(undefined, {
    pollingInterval: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (data === 'OK') {
      window.location.href = '/';
    }
  }, [data]);
}

export function useMaybeDelayedCheckServerStatus() {
  const [check] = useLazyIsServerUpQuery();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    // wtf is this?
    // well, on som enon-logged-in pages we do not amke any backend requests at all
    // so to check that the maintenancer is on, we fire a single request
    // then an interceptor will take care of everythign else
    !isAuthenticated && setTimeout(check, 1000);
  }, []);
}

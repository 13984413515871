import React from 'react';
import { Grid } from '@mui/material';
import BinanceBrokerImages from '../BinanceBrokerImages';
import FireblocksImage from '../FireblocksImage';
import { isFireblocks } from '../../../../whitelabel';

export const SealImagesBlock = () => {
  return (
    <Grid
      item
      container
      alignItems={'center'}
      justifyContent={'center'}
      gap={'8px'}
      sx={{ opacity: 0.7 }}
    >
      <Grid item>
        <img src={'/images/signup/secure-ssh.svg'} alt='ssl' id={'ssl_icon'} />
      </Grid>

      {!isFireblocks ? <BinanceBrokerImages /> : <FireblocksImage />}
    </Grid>
  );
};

export default SealImagesBlock;

export default {
  slug: 'uni_01',
  name: 'Unicapital',
  domain: 'qw.staging-zignaly.icu',
  zignalySuccessFee: 5,
  title: 'Unicapital: Licensed Forex Broker for Digital Assets Trading',
  description:
    'Discover Unicapital, the authorized forex broker for digital assets. Benefit from our expertise in forex trading and explore the world of digital assets with confidence.',
  social: {
    tiktok: 'https://tiktok.com/@unicapitaltech',
    twitter: 'https://twitter.com/unicapitaltech',
    youtube: 'https://www.youtube.com/@unicapitaltech',
    facebook: 'https://www.facebook.com/unicapitaltech/',
    linkedin: 'https://www.linkedin.com/company/unicapitaltech/',
    linktree: 'http://linktr.ee/unicapitaltech',
    telegram: 'https://t.me/unicapitaltech',
    instagram: 'https://www.instagram.com/unicapitaltech',
  },
  supportHelpCenter: 'https://support.unicapital.tech',
  tools: [],
  minInvestment: { BNB: 0, BTC: 0, ETH: 0, BUSD: 0, USDT: 0 },
  marketplaceMinScore: 0,
  mandatory2FA: [
    'createServiceApiKey',
    'deleteServiceApiKey',
    'editServiceApiKey',
    'exchangeWithdraw',
    'getExchangeDepositAddress',
    'investInService',
    'investOutAllService',
    'investOutService',
  ],
  splashscreen: null,
  baseApi: 'https://staging-api.zignaly.com/',
  baseReferralApi: 'https://referrals.zignaly.com/',
  locales: ['en'],
  logo: 'https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/61416dd9-78d0-42e0-45aa-1892b8e30000/public',
  favicon:
    'https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/1a38ab9f-f28d-4e72-b4a4-7a3e2b8b7100/public',
  pwaLogo:
    'https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/1a38ab9f-f28d-4e72-b4a4-7a3e2b8b7100/public',
  imageDeliveryImages: {
    logo: 'https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/61416dd9-78d0-42e0-45aa-1892b8e30000',
    favicon:
      'https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/1a38ab9f-f28d-4e72-b4a4-7a3e2b8b7100',
    pwaLogo: null,
    pwaLogoMac: null,
    banner:
      'https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/36d9a48e-fb4c-4c68-77ec-d67e1658d200',
  },
  baseTheme: 'dark',
  marketplaceCards: 3,
};

import { ZigTheme } from "../types";

const light: ZigTheme = {
  mode: "light",
  fontFamily: ["Avenir Next", "Roboto", "Helvetica", "Arial", "sans-serif"],
  // having this key is important
  // without it overriding would not work
  fontFamilyH1H6: undefined,
  palette: {
    neutral900: "#e0e0e0", // header table and highlighted BG color
    neutral800: "#e8e8e8", // menu main BG color
    neutral750: "#e0e0e0", // secondary menu BG and selected menu item
    neutral700: "#d9d9d9", // division table lines color and hover
    neutral600: "#c0c0c0", // markplace arrow color and some borders
    neutral500: "#dbcbcb", // N/A KILL ME
    neutral400: "#b0b0b0", // auxiliar texts color
    neutral300: "#696969", // highlighted texts color
    /**/ neutral200: "#424242", // menu and table header text color
    neutral175: "#808080", // auxiliar button text color
    neutral150: "#d2d2d2", // arrow color and sub button text color
    neutral100: "#696969", // header text color
    neutral000: "#222", // button text color
    secondary: "#FFFFFF",
    /**/ highlighted: "#9521f3",
    red: "#EF5350",
    criticalRed: "#D32F2F",
    green: "#26A69A",
    /**/ links: "#9521f3",
    /**/ yellow: "#ab931a",
    contrasting: "#000000",
    lightGrey: "#9ca3af",
    paleBlue: "#a890e5",
  },
  backgrounds: {
    zscoreGradientProfits: "linear-gradient(to right, #8ae1de, #73e0ff 47%, #0ceaae 88%)",
    zscoreGradientRisk: "linear-gradient(to right, #a091d4, #5533cf, #50a9ee)",
    zscoreGradientService: "linear-gradient(to right, #c878da, #6d0b82 42%, #aa21c7 76%, #f24196)",
    zscoreGradientBalanced: "linear-gradient(to right, #36995c, #46995c 42%, #76dd88 76%, #88c489)",
    zscore: "linear-gradient(47deg, #905fff, #b541ff)",
    inviteTableHighlight: `transparent`,
    zscoreProgressBar: "#ccc",
    tableHeader: "#ccc",
    marketplaceCarousel: `#e2e2e2`,
    tableRow: "#f4f4f4",
    body: `#ECEFF1`,
    header: "#f8f8f8",
    toastError: "#f8e6f1",
    toastSuccess: "#bfe8d6",
    neutralBg: "#F5F5F5",
    richTextEditor: `#f5f5f5`,
    buttonPrimary: "linear-gradient(47deg, #703fea, #9521f3)",
  },
  chart: {
    red: "#cc3965",
    green: "#039179",
    greenGradient: ["#03917966", "#039179"],
    greenMiniGradient: ["rgba(0,255,109,0.1)", "#03917966", "#039179"],
    redGradient: ["rgba(255,80,133,0.52)", "rgba(194,42,65,0.69)"],
    redMiniGradient: ["rgba(255,0,0,0.1)", "rgba(255,80,133,0.52)", "rgba(194,42,65,0.69)"],
  },
  boxShadows: {
    header: "inset 0 -1px 1px rgba(0, 0, 0, .085)",
    zigMenu: "0 1px 6px -2px #00000031",
  },

  imageColorOverride: "#ff8bd4",
};

export default light;

import { styled } from '@mui/material';
import { ZigTypography } from '@zignaly-open/ui';

export const Investor = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
`;

export const InvestorData = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const InvestorName = styled(ZigTypography)``;

export const TransactionContainer = styled('div')`
  margin: 42px -54px 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 42px 0 0;
  }

  // yes it's a hack but this makes our UI feel consistent
  #table__empty-message {
    padding-top: 35px;
    padding-bottom: 36px;
  }
`;

import { DialogProps } from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import ZModal from '../../../components/ZModal';
import { useTranslation } from 'react-i18next';
import LoginForm from '../components/LoginForm';
import SignupForm from '../components/SignupForm';
import { Box } from '@mui/material';
import { useIsAuthenticated } from '../../../apis/user/use';
import { SignupUserType } from '../../../apis/user/types';

function AuthModal({
  close,
  userType,
  authType = 'login',
  ...props
}: {
  close: () => void;
  userType: SignupUserType;
  authType: 'login' | 'signup';
} & DialogProps): React.ReactElement {
  const { t } = useTranslation(['auth', 'error']);
  const [view, setView] = useState(authType);
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    isAuthenticated && close();
  }, [isAuthenticated, close]);
  return (
    <ZModal
      title={view === 'login' ? t('log-in-title') : t('signup-title')}
      mobileFullScreen
      {...props}
      allowUnauth
      close={close}
      wide
    >
      <Box display={'flex'} justifyContent={'center'}>
        {view === 'login' ? (
          <LoginForm
            onClickSignUp={() => {
              setView('signup');
            }}
          />
        ) : (
          <SignupForm
            onClickLogin={() => setView('login')}
            plain
            withTitle={false}
            userType={userType}
            wrapperSx={{ padding: '0' }}
          />
        )}
      </Box>
    </ZModal>
  );
}

export default AuthModal;

export const TIME_TO_START_REFRESHING_TOKEN = 1800_000;
export const INSTANT_WITHDRAWAL_FEE = 10;
export const ASCENDEX_URL =
  'https://ascendex.com/en-us/register?inviteCode=I180THP3';
export const MEXC_URL = 'https://www.mexc.com/register?inviteCode=1DHh4';
export const GATEIO_URL = 'https://www.gate.io/signup/9545237';
export const BUY_CRYPTO_URL =
  'https://changelly.com/buy?from=usd&to=usdtbsc&amount=200&ref_id=q0s68wsie1uf9wza';

// export const HELP_URL = 'https://help.zignaly.com/hc/en-us'; // Use whitelabel.links.helpUrl
export const HELP_MANAGE_FUNDS_URL =
  'https://help.zignaly.com/en/articles/6885234-faq-s-for-wealth-managers#h_ecf5d6b611';
export const HELP_CREATE_SERVICE_MARKETPLACE_URL =
  'https://help.zignaly.com/en/articles/6845502-rules-for-being-listed-in-the-marketplace';
export const HELP_CREATE_ENABLE_2FA_URL =
  'https://help.zignaly.com/en/articles/7060936-secure-your-account-with-2fa';
export const HELP_REFERRAL =
  'https://help.zignaly.com/en/articles/8444336-unlock-more-benefits-with-zignaly-s-referral-program';
export const HELP_SERVICE_PROFILE_CHART =
  'https://help.zignaly.com/en/articles/6164630-service-profile-chart';

export const DOWNLOAD_GOOGLE_AUTHENTICATOR_URL =
  'https://support.google.com/accounts/answer/1066447';

export const CABIN_IN_THE_WOODS_URL =
  'https://www.youtube.com/watch?v=zrYvrzYAYEg';

export const FORBES_URL =
  'https://www.forbes.com/sites/tatianakoffman/2021/04/14/how-copy-trader-platforms-are-capitalizing-on-the-crypto-boom/?sh=71c6df5e64d3';

export const NASDAQ_URL =
  'https://www.nasdaq.com/articles/3-cryptos-to-buy-for-their-strong-use-cases-2021-10-14';

export const YAHOO_URL =
  'https://finance.yahoo.com/news/zignaly-launches-dao-unlock-expert-121500591.html';

export const BITCOIN_URL =
  'https://news.bitcoin.com/zignaly-lists-on-ascendex/';

export const BYBIT_FORM_URL = 'https://form.typeform.com/to/Ya7vwaKa';

export const ALTRADY_URL =
  'https://help.zignaly.com/en/collections/10185083-altrady';

export const BROKER_URL = 'https://www.binance.com/en/link';

export const MIN_INVESTMENT_FIREBLOCKS = 10;
export const AVERAGE_INVESTMENT = 1500;
export const WITHDRAWAL_VERIFICATION_THRESHOLD = 500000;

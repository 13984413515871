import React from 'react';
import { Grid } from '@mui/material';
import { isFireblocks } from 'whitelabel';

export const FireblocksImage = () => {
  if (!isFireblocks) {
    return null;
  }

  return (
    <Grid item>
      <img
        src={'/images/signup/fireblocks-seal.svg'}
        alt='fireblocks'
        id={'fireblocks_icon'}
      />
    </Grid>
  );
};

export default FireblocksImage;

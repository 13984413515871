import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ZigSubHeader,
  ZigTypography,
  ZigSubHeaderRoute,
} from '@zignaly-open/ui';
import {
  useIsServiceOwner,
  useTraderServices,
} from '../../../../apis/service/use';
import { TraderService } from '../../../../apis/service/types';
import { generatePath, useParams, useLocation } from 'react-router-dom';
import {
  ROUTE_TRADING_SERVICE,
  ROUTE_TRADING_SERVICE_API,
  ROUTE_TRADING_SERVICE_INVESTORS,
  ROUTE_TRADING_SERVICE_MANAGE,
  ROUTE_TRADING_SERVICE_EDIT,
} from '../../../../routes';
import { ZigMenuOption } from '@zignaly-open/ui/components/display/ZigMenu/types';
import { ZigPlusIcon } from '@zignaly-open/ui/icons';
import { useMediaQuery, useTheme } from '@mui/material';
import { useZModal } from 'components/ZModal/use';
import { useCanCreateService } from 'util/walls/util';
import CreateServiceModal from '../BecomeTraderLanding/modals/CreateServiceModal';
import { useIsAuthenticated } from 'apis/user/use';
import useMaybeShowAuthModalNotLoggedIn from '../../../../util/hooks/useMaybeShowAuthModalNotLoggedIn';
import { isFeatureOn } from '../../../../whitelabel';
import { Features } from '@zignaly-open/ps2-definitions';

function ServiceHeader() {
  const menuDropDownRef = useRef(null);
  const { t } = useTranslation('service-header');
  const { serviceId } = useParams();
  const currentPath = useLocation()?.pathname;
  const isOwner = useIsServiceOwner(serviceId);
  const theme = useTheme();
  const { showModal } = useZModal();
  const isAuthenticated = useIsAuthenticated();
  const navigateIfNotLoggedIn = useMaybeShowAuthModalNotLoggedIn();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const { data: myServicesList } = useTraderServices();
  const activeService = myServicesList?.find(
    (s: TraderService) => s.serviceId === serviceId,
  );

  const checkCanInvest = useCanCreateService();

  const onClickCreateService = () => {
    if (!isAuthenticated) {
      navigateIfNotLoggedIn();
    } else if (checkCanInvest()) {
      showModal(CreateServiceModal);
    }
  };
  const apiKeysServiceRoute = {
    label: t('dropdown.trade.links.api'),
    href: generatePath(ROUTE_TRADING_SERVICE_API, {
      serviceId,
    }),
    id: `service-management-header__service-api`,
  };
  const investorsServiceRoute = {
    label: t('investors-label'),
    href: generatePath(ROUTE_TRADING_SERVICE_INVESTORS, {
      serviceId,
    }),
    id: `service-management-header__investors`,
  };

  const options = useMemo<ZigSubHeaderRoute[]>(
    () => [
      {
        id: 'service-management-header__choose-service',
        label: activeService?.serviceName,
        secondaryTitle: t('dropdown.manageServices'),
        dense: true,
        sx: {
          '> div > div': { minWidth: '240px' },
        },
        routes: myServicesList
          ?.map(
            (service: TraderService): ZigMenuOption => ({
              id: `service-management-header__choose-${service?.serviceId}`,
              label: service?.serviceName,
              href: currentPath.replace(serviceId, service?.serviceId),
            }),
          )
          .concat(
            isFeatureOn(Features.CreateService)
              ? {
                  separator: true,
                  id: `service-management-header__create-service`,
                  label: (
                    <ZigTypography
                      component={'p'}
                      sx={{
                        textAlign: 'center',
                        fontSize: '14px',
                        width: '100%',
                        display: 'flex',
                        gap: '6px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      color={'links'}
                    >
                      <ZigPlusIcon
                        style={{
                          fontSize: '10px',
                          fill: theme.palette.neutral300,
                        }}
                      />
                      {t('dropdown.createService')}
                    </ZigTypography>
                  ),
                  onClick: onClickCreateService,
                }
              : [],
          ),
      },
      {
        label: t('managements-label'),
        href: generatePath(ROUTE_TRADING_SERVICE_MANAGE, {
          serviceId,
        }),
        id: `service-management-header__manage-funds`,
      },
      ...(md ? [apiKeysServiceRoute] : []),
      ...(lg ? [investorsServiceRoute] : []),
      {
        id: 'service-management-header__choose-option',
        label: lg ? t('dropdown.profile.title') : t('dropdown.other'),
        routes: [
          ...(!md ? [apiKeysServiceRoute] : []),
          ...(!lg ? [investorsServiceRoute] : []),
          {
            label: t('dropdown.profile.links.profile'),
            href: generatePath(ROUTE_TRADING_SERVICE, {
              serviceId,
            }),
            id: `service-management-header__service-profile`,
          },
          {
            label: t('dropdown.profile.links.profile-edit'),
            href: generatePath(ROUTE_TRADING_SERVICE_EDIT, {
              serviceId,
            }),
            id: `service-management-header__edit-service`,
          },
        ],
      },
    ],
    [menuDropDownRef, activeService, myServicesList, t, currentPath, md, lg],
  );

  if (!isOwner) {
    // Show nothing for non-service owners
    return null;
  }

  return (
    <ZigSubHeader
      menuSx={{
        hr: {
          marginTop: '15px !important',
          marginBottom: '15px !important',
        },
      }}
      routes={sm ? [options[0]] : options}
    />
  );
}

export default ServiceHeader;

import React from 'react';
import { Grid } from '@mui/material';

export const BinanceBrokerImages = () => {
  return (
    <>
      <Grid item>
        <img
          src={'/images/signup/funds-protected.svg'}
          alt='binance'
          id={'safu_icon'}
        />
      </Grid>
    </>
  );
};

export default BinanceBrokerImages;

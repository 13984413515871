import React, { MouseEvent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTitle } from 'util/title';
import {
  PageContainer,
  ZigLink,
  ZignalyLogotype,
  ZigTypography,
} from '@zignaly-open/ui';
import SignupForm from './components/SignupForm';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_PROFIT_SHARING } from '../../routes';
import {
  AVERAGE_INVESTMENT,
  BITCOIN_URL,
  FORBES_URL,
  MIN_INVESTMENT_FIREBLOCKS,
  NASDAQ_URL,
  YAHOO_URL,
} from '../../util/constants';
import { useMaybeSaveMissedDestinationUrl } from '../../util/navigation';
import {
  isFeatureOn,
  isFireblocks,
  isZignaly,
  whitelabel,
} from '../../whitelabel';
import { Features } from '../../whitelabel/type';
import LanguagesSelector from 'components/Navigation/LanguagesSelector';
import { supportedLanguages } from 'util/i18n/i18next';
import { Wrapper } from './components/SignupForm/styles';

const Signup: React.FC = () => {
  const { t } = useTranslation(['pages', 'sign-up']);
  const theme = useTheme();
  const navigate = useNavigate();
  useMaybeSaveMissedDestinationUrl();
  const lean = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  useTitle(t('pages:signup'));

  const infoBarItems = useMemo(
    () => [
      {
        title: t('sign-up:info-bar.item1.title'),
        description: t('sign-up:info-bar.item1.description'),
        image: 'person-icon.svg',
      },
      {
        title: t('sign-up:info-bar.item2.title'),
        description: t('sign-up:info-bar.item2.description'),
        image: 'percent-icon.svg',
      },
      {
        title: t('sign-up:info-bar.item3.title'),
        description: t('sign-up:info-bar.item3.description', {
          averageInvestment: AVERAGE_INVESTMENT,
          minInvestment: isFireblocks ? MIN_INVESTMENT_FIREBLOCKS : 1,
        }),
        image: 'dollar-icon.svg',
      },
    ],
    [t],
  );

  const logo = !isZignaly ? (
    <img src={whitelabel.logo} id='signup__logo' height='32' />
  ) : (
    <ZignalyLogotype width={'134px'} height={'32px'} id={'signup__logo'} />
  );
  const { state: locationState } = useLocation();

  return (
    <PageContainer
      style={{
        marginTop: isFeatureOn(Features.NewSignup) ? '24px' : '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {!isFeatureOn(Features.NewSignup) ? (
        <Wrapper>
          <SignupForm
            onClickLogin={() => navigate(ROUTE_LOGIN, { state: locationState })}
          />
        </Wrapper>
      ) : (
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          sx={{
            px: md ? '10%' : '3%',
            pb: 0,
          }}
          style={{
            paddingTop: 'env(safe-area-inset-top)',
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            marginBottom={'85px'}
            order={md ? 0 : 1}
            justifyContent={'center'}
            display={!md && 'flex'}
            mt={md && '52px'}
          >
            <Grid container direction='column' maxWidth={'450px'}>
              {md && (
                <Grid item>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    marginBottom={'60px'}
                    marginLeft={'-6px'}
                  >
                    {logo}
                  </Grid>
                </Grid>
              )}
              {!lean && (
                <Grid item marginBottom={'10px'}>
                  <ZigTypography
                    variant={'h2'}
                    fontSize={'25px'}
                    lineHeight={1.4}
                    color={'neutral100'}
                    textAlign={md ? 'unset' : 'center'}
                    id={'sign-up__invest-together-description'}
                  >
                    <Trans i18nKey={'sign-up:description'} t={t}>
                      {/*Didn't use thr react-dom-router-link because it messed up thr bg*/}
                      <ZigLink
                        target={'_self'}
                        href={generatePath(ROUTE_PROFIT_SHARING)}
                        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                          navigate(ROUTE_PROFIT_SHARING);
                          e.preventDefault();
                        }}
                        fontWeight={600}
                        sx={{
                          backgroundImage:
                            'linear-gradient(90deg, #3F3BB1, #138EA0) !important',
                          backgroundClip: 'text',
                          color: 'transparent',
                        }}
                      />
                    </Trans>
                  </ZigTypography>
                </Grid>
              )}
              <Grid
                item
                marginBottom={'10px'}
                textAlign={{ xs: 'center', md: 'start' }}
              >
                <img
                  src={'/images/signup/official-broker.svg'}
                  alt={''}
                  id={'sign-up__official-broker-icon'}
                />
              </Grid>

              {!lean &&
                infoBarItems.map((item, index) => (
                  <Grid
                    item
                    key={`--info-bar-item-${index.toString()}`}
                    display={'flex'}
                    paddingTop={'40px'}
                    justifyContent={'flex-start'}
                    maxWidth={'383px'}
                    width='100%'
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <img
                        src={`/images/signup/${item.image}`}
                        alt=''
                        width={'53px'}
                        height={'53px'}
                        id={`sign-up__info-bar-icon-${index.toString()}`}
                        style={{ flexShrink: 0 }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '26px',
                          flex: 1,
                        }}
                      >
                        <ZigTypography
                          variant={'h2'}
                          fontWeight={700}
                          fontSize={'22px'}
                          lineHeight={1.5}
                          color={'neutral100'}
                          id={`sign-up__info-bar-title-${index.toString()}`}
                        >
                          {item.title}
                        </ZigTypography>
                        <ZigTypography
                          variant={'body2'}
                          fontSize={'18px'}
                          lineHeight={1.3}
                          color={'neutral300'}
                          id={`sign-up__info-bar-description-${index.toString()}`}
                        >
                          {item.description}
                        </ZigTypography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={md ? 1 : 0}
            marginBottom={!md && '25px'}
          >
            <Grid container direction={'column'} alignItems={'center'}>
              <Grid
                item
                container
                justifyContent={
                  md
                    ? 'flex-end'
                    : supportedLanguages?.length > 1
                      ? 'space-between'
                      : 'center'
                }
                alignItems={'center'}
                sx={{ maxWidth: '500px !important' }}
                mb={2}
                minHeight={'36px'}
                flexWrap='nowrap'
              >
                {!md && (
                  <Grid
                    item
                    sx={{
                      img: {
                        maxWidth: '100%',
                        maxHeight: '32px',
                        height: 'auto',
                      },
                    }}
                  >
                    {logo}
                  </Grid>
                )}
                <Grid item>
                  <LanguagesSelector />
                </Grid>
              </Grid>
              <Grid container justifyContent={'center'}>
                <Wrapper>
                  <SignupForm
                    onClickLogin={() =>
                      navigate(ROUTE_LOGIN, { state: locationState })
                    }
                  />
                </Wrapper>
              </Grid>
              <Grid
                item
                container
                marginTop={'25px'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'18px'}
              >
                <Grid item>
                  <img
                    src={'/images/signup/secure-ssh.svg'}
                    alt=''
                    id={'ssl_icon'}
                  />
                </Grid>
                <Grid item>
                  <img
                    src={'/images/signup/funds-protected.svg'}
                    alt=''
                    id={'safu_icon'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {!lean && isZignaly && (
            <>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                marginTop={md && '50px'}
                gap={'15px'}
                order={3}
              >
                <Grid item>
                  <img
                    src={'/images/signup/secured.svg'}
                    alt=''
                    id={'sign-up__trusted-by-icon'}
                  />
                </Grid>
                <Grid item>
                  <ZigTypography
                    variant={'h2'}
                    fontSize={'25px'}
                    fontWeight={600}
                    textAlign={md ? 'unset' : 'center'}
                    id={'sign-up__trusted-by-label'}
                  >
                    {t('sign-up:trusted-by')}
                  </ZigTypography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                padding={'20px 0'}
                flexDirection={'column'}
                order={4}
              >
                <ZigTypography
                  fontSize={'20px'}
                  textAlign={md ? 'unset' : 'center'}
                  id={'sign-up__bottom-description'}
                >
                  {t('sign-up:bottom-description')}
                </ZigTypography>
                <Grid
                  item
                  container
                  width={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={'90px'}
                  padding={'20px 0'}
                >
                  <Grid item>
                    <ZigLink href={FORBES_URL} id={'sign-up__forbes-link'}>
                      <img
                        src={'/images/signup/forbes.svg'}
                        alt=''
                        id={'sign-up__forbes-icon'}
                      />
                    </ZigLink>
                  </Grid>
                  <Grid item>
                    <ZigLink href={NASDAQ_URL} id={'sign-up__nasdaq-link'}>
                      <img
                        src={'/images/signup/nasdaq.png'}
                        alt=''
                        id={'sign-up__nasdaq-icon'}
                      />
                    </ZigLink>
                  </Grid>
                  <Grid item>
                    <ZigLink href={YAHOO_URL} id={'sign-up__yahoo-link'}>
                      <img
                        src={'/images/signup/yahoo.svg'}
                        alt=''
                        id={'sign-up__yahoo-icon'}
                      />
                    </ZigLink>
                  </Grid>
                  <Grid item>
                    <ZigLink href={BITCOIN_URL} id={'sign-up__bitcoin-link'}>
                      <img
                        src={'/images/signup/bitcoin.svg'}
                        alt=''
                        id={'sign-up__bitcoin-icon'}
                      />
                    </ZigLink>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </PageContainer>
  );
};

export default Signup;
